<template>
  <a class="download-button" :href="download.src" :download="download.name" :title="`Descargar ${download.name} ${download.version}`">
    <iconic name="circle_down" />
    <span class="label">Descargar</span>
    <span class="version">{{ download.version }}</span>
  </a>
</template>

<script>
export default {
  props: ["download"],
};
</script>
